<template>
    <div id="app">
        <!-- <daohang> </daohang> -->

        <router-view></router-view>
        <!-- <dibudaohang> </dibudaohang> -->
    </div>
</template>

<script>
// import daohang from "./components/index/daohang.vue";
// import dibudaohang from "./components/dibudaohang/dibudaohang";
import CommonService from '@/service/CommonService';

export default {
    name: "App",
    components: {
        // daohang,
        // dibudaohang
    },
    setup() {
        //if username is null, then login guest user, if username equals guest, but token is expired, then refresh token
        // if (!localStorage.getItem("username")) {
        //     CommonService.login("guest", "123456").then(res => {
        //        //login guest
        //     });
        // }
        // else if (localStorage.getItem("username") === "guest") {
        //     if (localStorage.getItem("exp") && localStorage.getItem("exp") <= new Date().getTime()) {
        //         CommonService.login("guest", "JFat0Zdc").then(res => {
        //             if (res && res.access_token) {
        //                 localStorage.setItem("access_token", res.access_token)
        //                 localStorage.setItem("refresh_token", res.refresh_token)
        //                 localStorage.setItem("token_type", res.token_type)
        //                 localStorage.setItem("username", res.username)
        //                 localStorage.setItem("exp", res.exp * 1000)
        //             }
        //             console.log(res)
        //         });
        //         // CommonService.refreshToken().then(res => {
        //         //     if (res && res.access_token) {
        //         //         localStorage.setItem("access_token", res.access_token)
        //         //         localStorage.setItem("refresh_token", res.refresh_token)
        //         //         localStorage.setItem("token_type", res.token_type)
        //         //         localStorage.setItem("username", res.username)
        //         //         localStorage.setItem("exp", res.exp * 1000)
        //         //     }
        //         // })
        //     }
        // }
    },

};
</script>

<style>
body {
    margin: 0;
    overflow-x: hidden;
    background-color: #fff;

  display: grid;
}
.home{

}
</style>
