import { defineStore } from 'pinia';


export const useSearchStore = defineStore('search', {
	state: () => ({
        keyword:'',
		land_type:'',
        land_area:'',
        transaction_way:'',
        land_license_type:'',
        demand_purpose:'',
        transaction_status:'',
        land_size: '',
	}),
    getters:{
        params(state) {
            return {
                keyword:state.keyword,
                land_type:state.land_type,
                land_license_type:state.land_license_type,
                land_area:state.land_area,
                transaction_way:state.transaction_way,
                demand_purpose:state.demand_purpose,
                transaction_status:state.transaction_status,
                land_size:state.land_size,
            }
          },
    },
	actions: {

	},
});
