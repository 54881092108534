<template>
    <el-card :body-style="{ padding: '0px' }" shadow="hover" class="land-item-card">
        <div class="tag" :class="{ sold: supplyInfo.status === '已成交' }">
            <span v-if="supplyInfo.status === '已成交'">已成交</span>
            <span v-else>正在交易</span>
        </div>
        <img :src="supplyInfo.image" class="card-image" />
        <div class="card-content" style="padding: 14px">
            <h4><RouterLink to="/auction">{{ supplyInfo.name }}</RouterLink></h4>
            <div class="card-info-item">
                <i class="ti ti-coin-yuan"></i>
                <span>起始价：</span>
                <span class="money">{{ supplyInfo.price }}</span>
            </div>
            <div class="card-info-item">
                <i class="ti ti-coins"></i>
                <span>保证金：</span>
                <span class="money">{{ supplyInfo.deposit }}</span>
            </div>
            <div class="card-info-item">
                <i class="ti ti-align-box-left-top"></i>
                <span>地块编号：</span>
                <span>{{ supplyInfo.number }}</span>
            </div>
            <div class="card-info-item">
                <i class="ti ti-map-pin"></i>
                <span>地块位置：</span>
                <span>{{ supplyInfo.location }}</span>
            </div>
            <div class="card-info-item">
                <i class="ti ti-calendar-time"></i>
                <span>挂牌时间：</span>
                <span>{{ supplyInfo.startTime }}</span>
            </div>
            <div class="card-info-item">
                <span style="padding-left: 64px;">至：</span>
                <span>{{ supplyInfo.endTime }}</span>
            </div>
            <div class="card-info-item">
                <i class="ti ti-calendar-off"></i>
                <span>保证金截止：</span>
                <span>{{ supplyInfo.depositTime }}</span>
            </div>
            <!-- <p class="font16 end">
                <span>
                    <span>
                        <i class="icon-credit-card"></i>
                        保证金：
                    </span>
                    <span class="red">{{ supplyInfo.deposit }}</span>
                </span>
                <span>
                    <span class="mr10 ok">{{ supplyInfo.status }}</span>
                    <span class="info">块地详情</span>
                </span>
            </p> -->
        </div>
    </el-card>
</template>
  
<script>
export default {
    name: "SupplyItemCard",
    props: {
        supplyInfo: {
            type: Object,
            default: () => ({
                image: "",
                name: "XXX路商业用地挂牌转让",
                location: "xxx区xxxlu迪斯科飞机的设计发",
                number: "[2023]德阳转网007号",
                startTime: "2022-12-25 09:00:00",
                endTime: "2022-12-25 09:00:00",
                depositTime: "2023-12-25 17:00:00",
                price: "￥5800万元",
                deposit: "￥5800万元",
                status: "已成交",
            }),
        },
    },
};
</script>
  
<style lang="scss" scoped></style>
  