import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@tabler/icons-webfont/tabler-icons.min.css'
import * as echarts from 'echarts';
import './assets/css/global.scss';
import LandPanel from '@/components/LandPanel.vue'
import LandItemCard from '@/components/LandItemCard.vue'
import ListingLandItemCard from '@/components/ListingLandItemCard.vue'
import LandServiceItem from '@/components/LandServiceItem.vue'
import BreadCrumb from '@/components/Breadcrumb.vue'
import { createPinia, PiniaVuePlugin } from 'pinia'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;
Vue.use(ElementUI)

Vue.component('LandPanel', LandPanel)
Vue.component('LandItemCard', LandItemCard)
Vue.component('ListingLandItemCard', ListingLandItemCard)
Vue.component('LandServiceItem', LandServiceItem)
Vue.component('BreadCrumb', BreadCrumb)

Vue.axios.defaults.baseURL = '/' 

new Vue({
	router,
    pinia,
	render: (h) => h(App),
}).$mount("#app");
