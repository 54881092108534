<template>
    <div class="land-breadcrumb center land-box">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <!-- to为点击跳转 title为路由中的meta属性定义的标题 -->
            <el-breadcrumb-item v-for="(item, index) in breadList" :key="index" :to="item.meta.path">
                {{ item.meta.title }}
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>
<script>
export default {
    name: "BreadCrumb",
    data() {
        return {
            breadList: [],
        };
    }, watch: {
        $route() {//监听$route
            this.getBreadCrumb();
        },
    },
    methods: {
        isHome(route) {
            return route.name === "home";
        },
        getBreadCrumb() {
            let matched = this.$route.matched;
            //如果不是首页
            if (!this.isHome(matched[0])) {
                matched = [].concat(matched);
            }
            this.breadList = matched;
        },
    },
    created() {
        //生命周期中调用获取数据的方法
        this.getBreadCrumb();
    },
};
</script>