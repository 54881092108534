<template>
    <el-card :body-style="{ padding: '0px' }" shadow="never" :border="false" class="land-service-card">
        <img :src="item.image" class="card-image" />
        <div class="card-content" style="padding: 0 18px">
            <h4>{{ item.name }}</h4>
            <div class="card-info-item">
                <span>核心服务</span>
                <span>{{ item.service }}</span>
            </div>
            <div class="card-info-item">
                <span>服务类型</span>
                <span>{{ item.serviceType }}</span>
            </div>
            <div class="card-info-item">
                <span>服务范围</span>
                <span>{{ item.range }}</span>
            </div>
            <div class="card-info-item" style="margin-top: 8px;">
                <span style="color:#4493f4;font-size: 16px;">联系人：{{ item.contacts }}</span>
            </div>
            <div class="card-info-item">
                <span style="color:#4493f4;font-size: 16px;">联系人电话：{{ item.contactPhone }}</span>
            </div>
            <!-- <div style="margin-top: 8px;">
                <el-button type="warning" size="medium">帮我卖地</el-button>
                <el-button type="warning" size="medium" plain>帮我找地</el-button>
            </div> -->
        </div>
    </el-card>
</template>
<script>
export default {
    name: "LandServiceItem",
    data() {
        return {};
    },
    props: {
        item: {
            type: Object,
            default: () => ({
                image: "",
                name: "标题",
                service: "土地流转/寻找优质客户",
                serviceType:"土地评估",
                range: "不限",
                contacts:"俊子",
                contactPhone:"13333333333",
            }),
        },
    },
};
</script>
<style lang="scss" scoped>
</style>
