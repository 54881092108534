<template>
    <div class="land-panel">
        <el-tabs v-model="activeName" @tab-click="handleClick" :before-leave="moreState">
            <el-tab-pane name="title">
                <h2 slot="label">{{ title }}</h2>
            </el-tab-pane>
            <el-tab-pane label="默认隐藏tab" name="single-panel-default">
                <slot name="single-panel-default"></slot>
            </el-tab-pane>

            <el-tab-pane v-for="v in tabs" :label="v.label" :name="v.name" :key="v.name" style="padding-right: 0">
                <slot :name="v.name"></slot>
            </el-tab-pane>
            <el-tab-pane v-if="showMore" name="more" class="more-btn">
                <span slot="label">
                    <el-button type="text" style="color:#333;" @click="moreClick" v-if="moreLink.length === 0">更多 &gt;</el-button>
                    <router-link :to="moreLink"  v-if="moreLink.length > 0">更多 &gt;</router-link>
                </span>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
export default {
    name: "LandPanel",
    data() {
        return {
            activeName: this.activeTabName,
        };
    },
    watch:{
        activeName(val){
            this.$emit('changeActiveTabName', val)
        }
    },
    model:{
        prop: 'activeTabName',
        event: 'changeActiveTabName' 
    },
    props: {
        title: String,
        activeTabName: String,
        tabs: {
            type: Array,
            default: () => [{
                label: 'label1',
                name: 'name1'
            }, {
                label: 'label2',
                name: 'name2'
            }, {
                label: 'label3',
                name: 'name3'
            }]
        },
        showMore: {
            type: Boolean,
            default: true
        },
        moreLink: {
            type: String,
            default: ''
        },
        moreClick:{
            type: Function,
            default: ()=>{}
        }
    },
    methods: {
        handleClick() {
            //
        },
        moreState(tab, event) {
            if (tab == "more" || tab == "title") {
                return false;
            }
        },
    },
};
</script>
<style>
 #tab-tab-trade,#tab-tab-news,#tab-tab-policy{
   padding-right: 0;
 }
</style>

