<template>
    <el-card :body-style="{ padding: '0px' }" shadow="hover" class="land-item-card">
        <div :class="'tag processing' " v-if="getTransactionStatus(landInfo.transactionStatus)">
           {{ getTransactionStatus(landInfo.transactionStatus) }}

        </div>
        <img :src="image" class="card-image" @click="toDetail" style="cursor: pointer;"/>
        <div class="card-content" style="padding: 14px">
            <!-- <h4><RouterLink to="/tradingServices">{{ landInfo.title }}</RouterLink></h4> -->
            <h4 @click="toDetail"  style="cursor: pointer;">
                {{ landInfo.title }}
            </h4>
            <div class="card-info-item">
                <i class="ti ti-dimensions"></i>
                <span>面积：</span>
                <span>{{ landInfo.landSize }} 平方米</span>
            </div>
            <div class="card-info-item">
                <i class="ti ti-map-pin"></i>
                <span>所属行政区：</span>
                <span>{{ getXzqName(landInfo.regionId) }}</span>
            </div>
            <div class="card-info-item">
                <i class="ti ti-home-hand"></i>
                <span>土地用途：</span>
                <span style="overflow: hidden; white-space: nowrap;text-overflow:ellipsis;">{{ getLandPurposeName(landInfo.landPurpose) }} <!-- {{getLandPurposeItemName(landInfo.landPurpose, landInfo.subLandPurpose)}}--></span>

            </div>
          <div>
            <a  v-if="landInfo.id==160" target="_blank" href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/2e8ea2aef338495bad7d7c21da2564e3.png">
            <img  class="modeCard" src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/2e8ea2aef338495bad7d7c21da2564e3.png"></a>
            <a  v-if="landInfo.id==311" target="_blank" href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/26c023affae24cab9384c2ada4f38694.png">
            <img   class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/26c023affae24cab9384c2ada4f38694.png"></a>
            <a v-if="landInfo.id==161" target="_blank" href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/9ca208ec48d64d5fa17ad952696d2e60.png">
            <img    class="modeCard" src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/9ca208ec48d64d5fa17ad952696d2e60.png"></a>
            <a  v-if="landInfo.id==167" target="_blank" href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/8377cf5554ee4d8e90914608f27a6e84.png">
            <img   class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/8377cf5554ee4d8e90914608f27a6e84.png"></a>
            <a v-if="landInfo.id==168" target="_blank" href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/9ec36c22b58f4398b6a0064063cf5157.png">
            <img   class="modeCard"  src="https://land.dytudi.com.cn/api/admin/sys-file/dev-1304594149/9ec36c22b58f4398b6a0064063cf5157.png"></a>
            <a v-if="landInfo.id==293"  target="_blank" href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/d7b50c0297fd46679bb02c46f78188ab.png">
            <img  class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/d7b50c0297fd46679bb02c46f78188ab.png"></a>
            <a   v-if="landInfo.id==294" target="_blank"  href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/2e8ea2aef338495bad7d7c21da2564e3.png">
            <img  class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/2e8ea2aef338495bad7d7c21da2564e3.png"></a>
            <a   v-if="landInfo.id==204" target="_blank" href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/3fa3ac4116fd4d96a53b5b793ec374c1.png">
            <img  class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/3fa3ac4116fd4d96a53b5b793ec374c1.png"></a>
            <a  v-if="landInfo.id==324" target="_blank" href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/dcee36c3ab1c4aa8834ddac7448934c9.png">
            <img    class="modeCard"  src="https://land.dytudi.com.cn/api/admin/sys-file/dev-1304594149/dcee36c3ab1c4aa8834ddac7448934c9.png"></a>

            <a  v-if="landInfo.id==334" target="_blank" href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/fcacfdfffaec47d7a94c9892b197641c.png">
              <img    class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/fcacfdfffaec47d7a94c9892b197641c.png"></a>

            <a href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/d52ba5ba74d945f8a8edb88ad9ee84f8.png" v-if="landInfo.id==287" target="_blank" >
              <img   class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/d52ba5ba74d945f8a8edb88ad9ee84f8.png"></a>





            <a href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/4a1d9fd70092482d881c0c857a56c82a.png" v-if="landInfo.id==349" target="_blank" >
              <img   class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/4a1d9fd70092482d881c0c857a56c82a.png"></a>
            <a href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/3905bf636c3240a0bf9e0ddd29b63e6c.png" v-if="landInfo.id==353" target="_blank" >
              <img   class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/3905bf636c3240a0bf9e0ddd29b63e6c.png"></a>
            <a href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/5ba6f39502734cf4b0ecd1b4b2482ad7.png" v-if="landInfo.id==355" target="_blank" >
              <img   class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/5ba6f39502734cf4b0ecd1b4b2482ad7.png"></a>
            <a href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/5f1e33d7595442b59d280390f950a7b4.png" v-if="landInfo.id==357" target="_blank" >
              <img   class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/5f1e33d7595442b59d280390f950a7b4.png"></a>

            <a href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/d98b13b1ba8740c79b9f31013fe3f491.png" v-if="landInfo.id==358" target="_blank" >
              <img   class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/d98b13b1ba8740c79b9f31013fe3f491.png"></a>

            <a href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/1bcbdf0d424f4926b6475568bfda3b9b.png" v-if="landInfo.id==359" target="_blank" >
              <img   class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/1bcbdf0d424f4926b6475568bfda3b9b.png"></a>


            <a href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/3a274ec7a9d447639c4d546792af3e59.png" v-if="landInfo.id==361" target="_blank" >
              <img   class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/3a274ec7a9d447639c4d546792af3e59.png"></a>

            <a href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/78de862adc704b99ae74f972a8276376.png" v-if="landInfo.id==362" target="_blank" >
              <img   class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/78de862adc704b99ae74f972a8276376.png"></a>



            <a href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/a4597992a6834417be5be331f770970c.png" v-if="landInfo.id==363" target="_blank" >
              <img   class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/a4597992a6834417be5be331f770970c.png"></a>


            <a href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/3d884df3b2764ffcaf2af73ac39b3b7f.png" v-if="landInfo.id==364" target="_blank" >
              <img   class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/3d884df3b2764ffcaf2af73ac39b3b7f.png"></a>

            <a href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/7b9e42c79e574031bcdc18cfe4c14ea0.png" v-if="landInfo.id==365" target="_blank" >
              <img   class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/7b9e42c79e574031bcdc18cfe4c14ea0.png"></a>

            <a href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/a5c809555ad7401ebabe7ba47bc42786.png" v-if="landInfo.id==366" target="_blank" >
              <img   class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/a5c809555ad7401ebabe7ba47bc42786.png"></a>

            <a href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/1a21aefcb090494691c327112e7823af.png" v-if="landInfo.id==367" target="_blank" >
              <img   class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/1a21aefcb090494691c327112e7823af.png"></a>

            <a href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/4917d883bab04daab9209027743e32ab.png" v-if="landInfo.id==368" target="_blank" >
              <img   class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/4917d883bab04daab9209027743e32ab.png"></a>

            <a href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/6898a99ff10b4a058e692f08049eef4f.png" v-if="landInfo.id==369" target="_blank" >
              <img   class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/6898a99ff10b4a058e692f08049eef4f.png"></a>
            <a href="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/e5dc8b2767c64ceba0c63c3b2014da0b.png" v-if="landInfo.id==370" target="_blank" >
              <img   class="modeCard"  src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/e5dc8b2767c64ceba0c63c3b2014da0b.png"></a>


          </div>
          <div id="magnifying-glass" style="display:none; position:absolute;"></div>
          <div style="clear: both">

          </div>
            <div class="card-info-item">
                <i class="ti ti-clock-hour-9"></i>
                <span>发布时间：</span>
                <span>{{ landInfo.createTime }}</span>
            </div>

        </div>
    </el-card>
</template>
<script>

import {useDict} from "@/hooks/dict";
import {computed} from "vue";



const xzq = {
    'jinyang': '旌阳区',
    'jingkai': '市本级',
    'luojiang': '罗江区',
    'guanghan': '广汉市',
    'shifang': '什邡市',
    'mianzhu': '绵竹市',
    'zhongjiang': '中江县',
};


const transaction_status = {
    'processing': '正在交易',
    'success': '已成交'
}

export default {
    name: "LandItemCard",
    data() {
        return {
          isZoomed: false,
        };
    },

    props: {
      tabName:{
        type:String,
        default:''
      },
        landInfo: {
            type: Object,
            default: () => ({
                id: '',
                landPics: "",
                title: "name",
                landSize: 99.99,
                regionId: "武侯区",
                landPurpose: "商业用地",
                amount: "100万元",
                date: "2020-01-01 15:00:00",
            }),
        }
    },
    components: {  },
    computed: {
      imageStyle() {
        return {
          transform: this.isHovered ? 'scale(1.2)' : 'scale(1)',
          transition: 'transform 0.3s ease-in-out',
        };
      },
        image() {
            return this.landInfo.landPics ? this.landInfo.landPics.split(",")[0] +'?w=300': "";
        },
       demandPurposeItemOptionsComputed (demandPurpose) {
        const options = useDict(demandPurpose);
        return options[demandPurpose].value;
      },

    },
    methods: {
      handleMouseEnter() {
        console.log(1)
        this.isHovered = true;
      },
      handleMouseLeave() {
        console.log(2)
        this.isHovered = false;
      },
        getXzqName(id) {
            return xzq[id];
        },
        getLandPurposeName(id) {
          let land_purposes = useDict('demand_purpose');
          let tmp = '';
          land_purposes.demand_purpose.value.forEach((item) => {
            if (item.value === id) {
              tmp =  item.label;
            }
          })
          return tmp;
        },
      getLandPurposeItemName(demand_purpose,id) {
        let land_purposes = useDict(demand_purpose);
        let tmp = '';
        land_purposes[demand_purpose].value.forEach((item) => {
          if (item.value === id) {
            tmp =  item.label;
          }
        })
        return tmp;
      },
      getTransactionStatus(id){
            return transaction_status[id];
        },
        toDetail(){
            if(this.landInfo.auctionInfo &&  this.landInfo.transactionWay === "listing"){
                this.$router.push("/trade/gp/"+this.landInfo.auctionInfo.id);
            }
           else if( this.landInfo.transactionWay === "auction" && this.landInfo.auctionInfo){
             this.$router.push("/land/auction/"+this.landInfo.id);
           }
            else{
                this.$router.push("/land/detail/"+this.landInfo.id);
            }
        }
    },
};
</script>
<style lang="scss" scoped>
.modeCard{
  transition: all 0.5s;
  width: 70px;float: right; margin-top: -70px
}
.modeCard:hover{ //鼠标悬停时激活
  position: relative;
  z-index: 99;
  transition: 1s; //动画过渡的更加顺滑
  transform: scale(1.6); //放大倍数
}
</style>
